"use client";

import Link from "next/link";

export default function ErrorPage() {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center text-center gap-4 p-4">
      <h1>500</h1>
      <h3>Internal Server Error</h3>
      <p className="max-w-[450px] text-balance">
        Either the page is not found on Storyblok, or there were something wrong with the server.
      </p>
      <Link href="/" className="bg-primary-blueLightDawn hover:bg-white rounded-xl px-4 py-2 text-white border border-primary">
        Return Home
      </Link>
    </div>
  );
}
